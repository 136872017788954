<template>
  <ByCategoryReport />
</template>

<script>
import ByCategoryReport from '@/components/pages/product/ByCategoryReport'

export default {
  components: {
    ByCategoryReport
  }
}
</script>
