<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader breadcrumb="Product" title="By Category Report" />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div>
        <FilterCard>
          <q-form ref="filterForm" @submit="onFilter">
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-6 col-md-2">
                <FormDate
                  label="Month"
                  mask="####/##"
                  v-model="monthYear"
                  defaultView="YearMonth"
                  :value="monthYear"
                  :rules="[VALIDATION.required]"
                  @input="onSelectDate"
                  tabindex="1"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-2">
                <FormSelect
                  label="Sort By"
                  v-model="sortBy"
                  :values="sortBy"
                  :options="sortByOptions"
                  :rules="[VALIDATION.required]"
                  tabindex="2"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-2">
                <FormSelect
                  label="Top"
                  v-model="top"
                  :values="top"
                  :options="topOptions"
                  :rules="[VALIDATION.required]"
                  tabindex="3"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-2">
                <SelectStores
                  v-model="store"
                  :values="store"
                  :multiple="false"
                  @updateStore="$event => (store = $event)"
                  tabindex="4"
                  withAll
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-2">
                <FormSelect
                  label="Compare"
                  v-model="compare"
                  :values="compare"
                  :options="compareOptions"
                  :rules="[VALIDATION.required]"
                  tabindex="5"
                />
              </div>

              <div class="col-xs-12 col-sm-12 col-md-2 text-right">
                <Button
                  variant="primary"
                  label="Filter"
                  icon="o_filter_alt"
                  :loading="false"
                  :disabled="false"
                  type="submit"
                  tabindex="6"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <Loader :loading="!isFiltered && loadingSales" />

      <NoDataCard v-if="noData" />

      <div class="row items-start q-col-gutter-md" v-if="hasData">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard :header="`Top Category ${chartTitle}`">
            <HorizontalMultiBarChart
              id="barchart"
              axis="xy"
              :data="salesData"
              :sortType="sortBy"
              :legendType="compare"
            />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard :header="`Gainers & Losers`">
            <BarChartGainLoss id="gainchart" axis="xy" :data="gainLossData" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12">
          <Table
            :rows="salesTableData"
            :columns="salesTableHeader"
            :loading="loadingTable"
            :limit="limitPage"
            :page="pageNo"
            :total="totalCount"
            @onRequest="onRequest"
          >
            <template v-slot:body-cell-salesTrend="props">
              <q-td :props="props">
                <LineChartMini :data="props.row.trendData" />
              </q-td>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    :footer="false"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <div class="p-4">
      <h5 class="q-mb-md">By Category Report</h5>
      <p>
        {{ HELP.BY_CATEGORY_REPORT.description }}
      </p>

      <q-separator class="q-my-md" />

      <h6 class="q-mb-md text-sm">Definition of Terms</h6>
      <Table
        style="max-height: 50vh"
        :rows="HELP.BY_CATEGORY_REPORT.terms"
        :columns="HELP.TABLE"
        :limit="0"
        hide-bottom
      >
        <template v-slot:body="props">
          <q-tr>
            <q-td>
              <strong>{{ props.row.name }}</strong>
            </q-td>
            <q-td :style="{ whiteSpace: 'normal' }">
              {{ props.row.description }}
            </q-td>
          </q-tr>
        </template>
      </Table>
    </div>
  </Modal>
</template>

<script>
import { ref, computed, inject, watch, onBeforeMount } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { SimpleCard, FilterCard, NoDataCard } from '@/components/cards'
import { FormDate, FormSelect } from '@/components/inputs'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import {
  HorizontalMultiBarChart,
  BarChartGainLoss,
  LineChartMini
} from '@/components/charts'
import Loader from '@/components/Loader'
import { SelectStores } from '@/components/customs'

import { HELP, optionsTop, optionsSortBy, optionsCompare } from '@/constants'

import {
  DATE,
  FORMAT,
  VALIDATION,
  EXPORT,
  Toast,
  getIconUrl,
  fetchData
} from '@/tools'

export default {
  name: 'ByCategoryReport',
  components: {
    PageHeader,
    PageContent,
    SimpleCard,
    FilterCard,
    NoDataCard,
    FormDate,
    FormSelect,
    Button,
    Table,
    Modal,
    HorizontalMultiBarChart,
    BarChartGainLoss,
    LineChartMini,
    Loader,
    SelectStores
  },
  setup() {
    const { showToast } = Toast()
    const state = inject('store')?.state

    const {
      data: dataSales,
      error: errorSales,
      loading: loadingSales,
      post: postSales
    } = fetchData()

    const isRetailer = ref(state?.accountType?.retailer)
    const showModal = ref(false)
    const loadingTable = ref(false)
    const isFiltered = ref(false)
    const limitPage = ref(10)
    const pageNo = ref(1)
    const totalCount = ref(0)
    const filterForm = ref(null)
    const monthYear = ref(
      DATE.toFriendlyDate(
        DATE.toFriendlyFirstDay(DATE.subtractDays(new Date(), 1, 'months'))
      )
    )
    const store = ref('0')
    const top = ref(optionsTop[0].value)
    const sortBy = ref(optionsSortBy[0].value)
    const topOptions = ref(optionsTop)
    const sortByOptions = ref(optionsSortBy)
    const compare = ref(optionsCompare[0].value)
    const compareOptions = ref(optionsCompare)
    const salesData = ref([])
    const gainLossData = ref([])
    const chartTitle = ref(
      sortByOptions.value.find(item => item.value === sortBy.value)['label']
    )
    const trendTitle = ref(
      `${
        sortByOptions.value.find(item => item.value === sortBy.value)['label']
      } Trend`
    )
    const salesTableData = ref(null)
    const salesTableHeader = ref([
      {
        name: 'category',
        label: 'Category Name',
        field: 'category',
        align: 'left',
        sortable: true
      },
      {
        name: 'sales',
        label: 'Sales',
        field: 'sales',
        format: val => FORMAT.toCurrency(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'trxCount',
        label: 'Trx Count',
        field: 'trxCount',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'basketSize',
        label: 'Basket Size',
        field: 'basketSize',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'basketPenetration',
        label: 'Basket Penetration',
        field: 'basketPenetration',
        format: val => `${val}%`,
        align: 'center',
        sortable: true
      },
      {
        name: 'customersP1',
        label: 'Customers (P1)',
        field: 'customersP1',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'customersP3',
        label: 'Customers (P3)',
        field: 'customersP3',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'salesTrend',
        label: trendTitle,
        field: 'salesTrend',
        align: 'center',
        sortable: true
      },
      {
        name: 'gainLoss',
        label: 'Gain/Loss',
        field: 'gainLoss',
        format: val => `${val}%`,
        align: 'center',
        sortable: true
      }
    ])

    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )

    const noData = computed(
      () => salesTableData.value && salesTableData.value.length === 0
    )

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onFilter = () => {
      if (!isFiltered.value) {
        limitPage.value = 10
        pageNo.value = 1
        totalCount.value = 0
      }

      filterForm.value.validate().then(success => {
        if (success) {
          chartTitle.value = sortByOptions.value.find(
            item => item.value === sortBy.value
          )['label']

          trendTitle.value = `${
            sortByOptions.value.find(item => item.value === sortBy.value)[
              'label'
            ]
          } Trend`

          const payload = {
            pagination: {
              page: pageNo.value,
              per_page: limitPage.value
            },
            filters: {
              month_year: DATE.toFriendlyDate(
                DATE.toFriendlyFirstDay(monthYear.value)
              ),
              store: store.value,
              sort_by: sortBy.value,
              compare: compare.value,
              limit: top.value
            }
          }

          if (isRetailer.value) {
            payload.filters.principal = state?.defaultID

            postSales('/v1/retailer/product/by-category-report', payload)
          } else {
            payload.filters.retailer = state?.defaultID

            postSales('/v1/product/by-category-report', payload)
          }
        } else {
          showToast('Please fill all the required fields', 'info')
        }
      })
    }

    const onSelectDate = data => {
      monthYear.value = data
    }

    const exportTable = () => {
      let barchartImg, gainchartImg

      FORMAT.svg2img('barchart', 550, 650, function (pngData) {
        barchartImg = pngData
      })

      FORMAT.svg2img('gainchart', 650, 450, function (pngData) {
        gainchartImg = pngData
      })

      setTimeout(() => {
        if (barchartImg && gainchartImg) {
          const initialPosition = 8
          const barChartPosition = salesData.value.length * 3
          const gainChartPosition = gainLossData.value.length + initialPosition

          EXPORT.exportToExcel({
            header: salesTableHeader.value,
            data: salesTableData.value,
            filename: 'by_category_report',
            chart: barchartImg,
            chart2: gainchartImg,
            chartName: `Top Category ${chartTitle.value}`,
            chartName2: 'Gainers & Losers',
            position: {
              table: barChartPosition,
              chart: {
                col: initialPosition,
                row: barChartPosition
              },
              chart2: {
                col: gainChartPosition,
                row: gainChartPosition
              }
            }
          })
        }
      }, 500)
    }

    const onRequest = props => {
      const { page, rowsPerPage } = props.pagination
      isFiltered.value = true
      pageNo.value = page
      limitPage.value = rowsPerPage

      onFilter()
    }

    onBeforeMount(() => {
      watch([dataSales, errorSales, loadingSales], () => {
        if (loadingSales.value) {
          if (isFiltered.value) {
            loadingTable.value = true
          } else {
            salesTableData.value = null
          }
        } else {
          if (errorSales?.value) {
            showToast(
              errorSales?.value?.message ??
                'There was a problem fetching sales.',
              'danger'
            )
          } else if (dataSales?.value) {
            totalCount.value = dataSales?.value?.length

            salesTableData.value = dataSales?.value?.map(item => ({
              category: item?.category_name,
              sales: FORMAT.toFixedNumber(item?.sales ?? 0),
              trxCount: item?.trx_count ?? 0,
              basketSize: item?.basket_size ?? 0,
              basketPenetration: Math.round(item?.basket_penetration ?? 0),
              customersP1: Math.round(item?.customers_p1 ?? 0),
              customersP3: Math.round(item?.customers_p3 ?? 0),
              salesTrend: JSON.parse(item?.trend)
                .toString()
                ?.replace(/,/g, ', '),
              trendData: JSON.parse(item?.trend).map((item, index) => ({
                name: index,
                value: item
              })),
              gainLoss: Math.round(item?.gain_loss ?? 0)
            }))

            salesData.value = dataSales?.value
              ?.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? -1 : 1))
              .map((item, index) => ({
                index: index,
                name: item?.category_name,
                value: FORMAT.toFixedNumber(item[sortBy.value] ?? 0),
                value2: FORMAT.toFixedNumber(item[`${sortBy.value}_ago`] ?? 0)
              }))

            gainLossData.value = dataSales?.value
              ?.sort((a, b) => (a.gain_loss > b.gain_loss ? -1 : 1))
              .map((item, index) => ({
                index: index,
                name: item?.category_name,
                value: Math.round(item?.gain_loss ?? 0) * 0.01
              }))
          }

          isFiltered.value = false
          loadingTable.value = false
        }
      })
    })

    return {
      HELP,
      showModal,
      loadingSales,
      loadingTable,
      isFiltered,
      limitPage,
      pageNo,
      totalCount,
      filterForm,
      monthYear,
      store,
      top,
      topOptions,
      sortBy,
      sortByOptions,
      compare,
      compareOptions,
      salesData,
      gainLossData,
      chartTitle,
      trendTitle,
      salesTableHeader,
      salesTableData,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasData,
      noData,
      onToggleModal,
      onFilter,
      onSelectDate,
      exportTable,
      onRequest
    }
  }
}
</script>
